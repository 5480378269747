module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-133198025-1","anonymize":true},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Periplus AG - Software Development Company","short_name":"Periplus","start_url":"/","theme_color":"#ffffff","background_color":"#ffffff","display":"standalone","icon":"src/img/icon.png","icons":[{"src":"favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3e7ca35fa80cbb4c2de1175c5529444e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1104}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
