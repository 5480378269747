const borderRadius = "4px"

export const theme = {
  worldMap: {
    continent: {
      base: "10px",
      active: 12,
    },
    place: {
      base: "18px",
      active: "28px",
    },
  },
  global: {
    control: {
      border: {
        radius: borderRadius,
      },
    },
    focus: { border: { color: "transparent" } },
    shadows: {
      float: "0 4px 6px 0 rgb(0 0 0 / 5%)",
      lightPurple: "0px 10px 20px rgba(139, 138, 172, 0.1)",
      boxPurple: "0px 4px 10px #d2ccff",
      boxPurpleHover: "0px 12px 20px #b5acff",
    },
    gradients: {
      purpleBlueDark:
        "linear-gradient(357.28deg, #41407B -27.41%, #211E32 88%);",
      blueToDark: "linear-gradient(180deg, #202E65 0%, #262E4F 100%)",
      adit:
        "linear-gradient(258.41deg, #875CFF 1.56%, #6C3DC6 68.14%, #4E4376 110.83%);",
      spoor: "linear-gradient(280.62deg, #62C3A6 7.89%, #00527E 95.07%);",
      linearPurple:
        "linear-gradient(258.41deg, #875CFF 1.56%, #6C3DC6 68.14%, #4E4376 110.83%)",
      whiteToGray:
        "linear-gradient(rgb(255, 255, 255) 0%, rgb(243, 246, 248) 50%, rgb(243, 246, 248) 100%)",
      terminal: "linear-gradient(65.52deg, #010033 0%, #260033 100%)",
    },
    colors: {
      brand: "#5648CB",
      "accent-1": "#604DFF",
      "accent-1-dark": "#5F54BF",
      "accent-2": "#B5ACFF",
      "accent-3": "#E7E4FF",
      "accent-4": "#F45B69", // Red
      "accent-5": "#f8f9fb",
      "light-1": "#F8F8F8",
      "light-2": "#F2F2F2",
      "light-3": "#EDEDED",
      "light-4": "#DADADA",
      "dark-1": "#343A40",
      "dark-2": "#555555",
      "dark-3": "#777777",
      "dark-4": "#999999",
      "dark-5": "#999999",
      paragraphDark: "#737A96",
      paragraphLight: "#d5daf0",
      lightBackground: "#F8F9FA",
      white: "#FFFFFF",
      text: {
        light: "#555555",
        dark: "#f8f8f8",
      },
    },
    font: {
      family: "Roboto",
      style: "sans-serif",
    },
  },
  accordion: { border: undefined },
  anchor: {
    fontWeight: 300,
  },
  button: {
    border: {
      radius: borderRadius,
    },
  },
  heading: {
    level: {
      "1": {
        font: {},
      },
      "2": {
        font: {},
        large: {
          size: "40px",
          height: "38px",
          maxWidth: "602px",
        },
        xlarge: {
          size: "40px",
          height: "38px",
          maxWidth: "602px",
        },
      },
      "3": {
        font: {},
      },
      "4": {},
    },
  },
}
